<template>
  <div>
    <div class="headbar">
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>新手指导</el-breadcrumb-item>
          <el-breadcrumb-item>指导列表</el-breadcrumb-item>
          <el-breadcrumb-item>指导编辑</el-breadcrumb-item>
        </el-breadcrumb>

      </div>

    </div>
    <el-form :model="EditItem" ref="EditItem" label-width="120px">

      <el-form-item label="标题">
        <el-input v-model="EditItem.title" placeholder="标题"></el-input>
      </el-form-item>
      <el-form-item label="内容">
        <div style="line-height: initial; width: 100%; ">
          <UEditor ref="ue" @ready="editorReady" style="width: 100%; "></UEditor>
        </div>
      </el-form-item>
      <el-form-item label="发布对象">
        <el-select size="small" v-model="EditItem.type">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="学生" :value="1"></el-option>
          <el-option label="教师" :value="2"></el-option>
        </el-select>

      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number :min="0" v-model.trim="EditItem.sort" placeholder="请输入排序号(数字)"
                         controls-position="right"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveForm">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import UEditor from "../notify/ueditor.vue";
export default {
  components: { UEditor },
  data() {
    return {
      EditItem: {
        id: 0,
        type:0,
        sort:100,
        content: ""
      },
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.query.id) {
        this.EditItem.id = this.$route.query.id
        this.getInfo()
      }
    },500)
  },
  methods: {
    getInfo() {
      this.$http.post('/api/new_guidance_info', {id:this.EditItem.id}).then(res => {
        this.EditItem = res.data
        this.editor.setContent(this.EditItem.content)
      })
    },
    saveForm () {
      this.$http.post("/api/new_guidance_edit", this.EditItem).then(res => {
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        this.$router.push('/guide')
      })
    },
    editorReady(editorInstance) {

      editorInstance.setContent(this.EditItem.content);
      editorInstance.addListener('contentChange', () => {
        this.EditItem.content = editorInstance.getContent();
      });
      this.editor = editorInstance;
    },
  }
};
</script>

<style scoped>

</style>
